export const ProductMap = {
    GET_PRODUCT: 'GET_PRODUCT',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',

    SET_SELECTED_IMAGE: 'SET_SELECTED_IMAGE',
    SET_PREVIEW_MODAL: 'SET_PREVIEW_MODAL',
    SET_PROCEED_PREVIEW: 'SET_PROCEED_PREVIEW',

    SET_SHOW_TOP_WARNING_MESSAGE: 'SET_SHOW_TOP_WARNING_MESSAGE',

    SET_TWO_SIDE: 'SET_TWO_SIDE',
    SET_AVAILABLE_MODE: 'SET_AVAILABLE_MODE',

    CHECK_IMAGE_DPI_START: 'CHECK_IMAGE_DPI_START' ,
    CHECK_IMAGE_DPI_SUCCESS: 'CHECK_IMAGE_DPI_SUCCESS',
    CHECK_IMAGE_DPI_ERROR: 'CHECK_IMAGE_DPI_ERROR'
}

export const ProductActions = {
    getProductDetails: (data) => ({type: ProductMap.GET_PRODUCT, payload: data}),
    getProductDetailSuccess: (data) => ({type: ProductMap.GET_PRODUCT_SUCCESS, payload: data}),
    getProductDetailError: () => ({type: ProductMap.GET_PRODUCT_ERROR}),

    setSelectedImage: (data) => ({type: ProductMap.SET_SELECTED_IMAGE, payload: data}),
    showPreviewModal: (data) => ({type: ProductMap.SET_PREVIEW_MODAL, payload: data}),
    showProceedPreview: (data) => ({type: ProductMap.SET_PROCEED_PREVIEW, payload: data}),

    setShowTopMessage: (data) => ({type: ProductMap.SET_SHOW_TOP_WARNING_MESSAGE, payload: data}),
    
    onModeChange: (data) => ({type: ProductMap.SET_TWO_SIDE, payload: data}),
    setAvailableMode: (data) => ({type: ProductMap.SET_AVAILABLE_MODE, payload: data}),

    checkImageDpi: (data, cb) => ({type: ProductMap.CHECK_IMAGE_DPI_START, payload: {data, cb}}),
    checkImageDpiSuccess: () => ({ type: ProductMap.CHECK_IMAGE_DPI_SUCCESS }),
    checkImageDpiError: () => ({ type: ProductMap.CHECK_IMAGE_DPI_ERROR }),
}