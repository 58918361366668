import { ProductMap } from './product.action';

const initialState = {
    productDetails: null,
    selectedImage: null,
    isLoading: false,
    previewModal: false,
    proceedPreview: false,
    showTopWarning: false,
    selectedMode: '',
    availableModes: '',
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ProductMap.GET_PRODUCT: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case ProductMap.GET_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productDetails: action.payload,
                selectedImage: action.payload.image[0],
                selectedMode: action.payload.image[0].mode,
            }
        }

        case ProductMap.GET_PRODUCT_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case ProductMap.SET_SELECTED_IMAGE: {
            return {
                ...state,
                selectedImage: action.payload,
            }
        }

        case ProductMap.SET_PREVIEW_MODAL: {
            return {
                ...state,
                previewModal: action.payload,
                proceedPreview: false,
            }
        }

        case ProductMap.SET_PROCEED_PREVIEW: {
            return {
                ...state,
                previewModal: action.payload,
                proceedPreview: action.payload,
            }
        }

        case ProductMap.SET_SHOW_TOP_WARNING_MESSAGE: {
            return {
                ...state,
                showTopWarning: action.payload,
            }
        }

        case ProductMap.SET_TWO_SIDE: {
            return {
                ...state,
                selectedMode: action.payload,
            }
        }

        case ProductMap.SET_AVAILABLE_MODE: {
            return {
                ...state,
                availableModes: action.payload,
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}