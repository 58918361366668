import React, { useContext, useRef, useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';
import { LoadingOverlayContext } from '../../../../_core/contexts/loadingOverlay.context';
import {ProductActions} from '../../image-container/_redux';
import { toast } from 'react-toastify';

const ImageUploadSubMenu = () => {

    const { addUploadImage } = useContext(DesignCanvasContext);
    const { toggleSpinner, visible } = useContext(LoadingOverlayContext);
    const [showUrlInput, setShowUrlInput] = useState(false)
    const [urlInput, setUrlInput] = useState("");

    const fileInp = useRef();

    const dispatch = useDispatch();

    const onFileUpload = () => {
        setShowUrlInput(false);
        setUrlInput("");
        if (fileInp.current) {
            fileInp.current.click()
        }
    }

    const lowImageResolutionMsg = () => {
        dispatch(ProductActions.setShowTopMessage(true));
    }

    const afterDpiCheck = (file) => {
        addUploadImage('file', file, () => {
            toggleSpinner(false);
            fileInp.current.value = "";
        });
    }

    const onFileUploadChange = (e) => {
        if (e.target && e.target.files && e.target.files.length) {
            // toggleSpinner(true);
            // let formData = new FormData();
            // formData.append('images', e.target.files[0]);
            // dispatch(ProductActions.checkImageDpi(formData, () => afterDpiCheck(e.target.files[0])));

            addUploadImage('file', e.target.files[0], lowImageResolutionMsg);
            fileInp.current.value = "";
        }
    }

    const imageNotify = () => toast.dark("Error while loading an image from URL.", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000
    });

    const onFileUrlUpload = () => {
        if (urlInput) {
            addUploadImage('url', urlInput + "?timestamp=" + Date.now(), imageNotify);
        }
    }

    return (
        <div className="image-menu-main">
            <div className="menu">
                <div style={{ display: "flex" }}>
                    <div className="image-menu" onClick={onFileUpload}>
                        <div className="image-icon">
                            <FontAwesomeIcon icon={faUpload} style={{ color: '#ef5350', fontSize: "40px" }} />
                        </div>
                        <div className="image-info">
                            <span className="image-name">
                                Upload
                        </span>
                            <input type="file" hidden ref={fileInp} accept="image/*" onChange={onFileUploadChange} />
                        </div>
                    </div>
                    <div className="image-menu" onClick={() => setShowUrlInput(true)}>
                        <div className="image-icon">
                            <FontAwesomeIcon icon={faPaperclip} style={{ color: '#06d79c', fontSize: "40px" }} />
                        </div>
                        <div className="image-info">
                            <span className="image-name">
                                Image URL
                            </span>
                        </div>
                    </div>
                </div>

                {
                    showUrlInput ? (
                        <div className="image-url-input">
                            <div className="input-content">
                                <div className="form-group">
                                    <label>Image Url</label>
                                    <div className="input-group">
                                        <input type="text" onChange={(e) => setUrlInput(e.target.value)} />
                                        <button disabled={!urlInput} onClick={onFileUrlUpload}>
                                            INSERT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default ImageUploadSubMenu;