import React, { useContext } from 'react';
import { AsideMenuContext } from '../../../_core/contexts/aside-menu.context';
import { DesignCanvasContext } from '../../../_core/contexts/design-canvas.context';
import AsideMenuList from './aside-menu-list';
import AsideSubMenu from './aside-submenu';

export const AsideMenu = () => {

    const { activeAsideMenu, toggleAsideMenu, showSubMenu, toggleSubMenu } = useContext(AsideMenuContext);
    const { clearFreeInput } = useContext(DesignCanvasContext)

    return (
        <div className="aside-menu">
            <AsideMenuList
                activeAsideMenu={activeAsideMenu}
                toggleAsideMenu={toggleAsideMenu}
                clearFreeInput={clearFreeInput}
            />
            {
                <AsideSubMenu activeAsideMenu={activeAsideMenu} showSubMenu={showSubMenu} toggleSubMenu={toggleSubMenu} />
            }
        </div>
    )
}