import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from './modules/header';
import { AsideMenu } from './modules/aside-menu';
import { MainImageContainer } from './modules/image-container';
import { Disclaimers } from './modules/disclaimer';
import { AuthActions } from './modules/auth/_redux/auth.actions';
import Spinner from './modules/loader/spinner'
import { LoadingOverlayContext } from '../_core/contexts/loadingOverlay.context';

const BasePage = () => {

    const { visible, toggleSpinner } = useContext(LoadingOverlayContext);
    const dispatch = useDispatch();

    useEffect(() => {
        toggleSpinner(true);
        let eventHandler = (event) => {
            if(event.data.token || event.data .isAnonymous) {
                let isAnonymous = event.data.isAnonymous;
                let token = event.data.token;
                let origin  = event.data.origin;
                dispatch(AuthActions.validateToken({ token, isAnonymous }));
                dispatch(AuthActions.setOrigin(origin));
            }
        }
        window.addEventListener("message", eventHandler, false);

        // return () => {
        //     window.removeEventListener("message", eventHandler);
        // }
        // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmU0ZjRiYmNkMjMzZGVlNjk2N2IyYzQiLCJlbWFpbCI6Im5pbGVzaC5sdW5kd2FuaUBjb2RlemVyb3MuY29tIiwidXNlcl9pZCI6InF3ZXR0eSIsImlhdCI6MTYwOTE0NzU4N30.-G4lqGppGhFZoQ-7lD5fu7AUPCalixmBAFkiacKJBhc";
        // dispatch(AuthActions.validateToken({ token }));
    }, []);

    return (
        <div>
            <Header />
            <Disclaimers />
            <div className="workspace">
                <div className="menu-side-nav">
                    <AsideMenu />
                </div>
                <MainImageContainer />
            </div>
            {
                visible ? (
                    <Spinner />
                ) : null
            }
        </div>
    )
}

export default BasePage;