import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minHeight: '400px',
        minWidth: '950px'
    },
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
    }
};

export const PreviewModal = (props) => {
    const {
        previewModal,
        showPreviewModal,
        primaryCanvas,
        productDetails,
        selectedImage,
        getCanvasWithBackImg,
        variantCanvas,
        addVariantToCanvas,
        PreviewFullWrap,
        proceedPreview,
        toggleSpinner,
        dataURItoBlob,
        user,
        artworksActions,
        combineImages,
        secondCanvas,
        twoSideCanvasUrl,
        selectedMode,
        svgToBlob,
    } = props;

    const [images, setImages] = useState([]);
    const [fullImage, setFullImage] = useState({ show: false, url: "" })

    const previewImgRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (previewModal) {
            toggleSpinner(true);
            let images = [];
            if (selectedMode === "Two Side") {
                images = productDetails.image.filter(img => img.color === selectedImage.color && img.variant === "Two Sided");
            }
            if (selectedMode === "Full Wrap") {
                images = productDetails.image.filter(img => img.color === selectedImage.color && img.variant !== "Two Sided" && img.variant !== "Full Wrap");
                if (!images.length) {
                    images = productDetails.image.filter(img => img.color === selectedImage.color && img.variant === "Full Wrap");
                }
            }
            if (selectedMode === "One Side") {
                images = productDetails.image.filter(img => img.color === selectedImage.color && img.variant !== "Two Sided" && img.variant !== "Full Wrap");
            }
            if (selectedMode === "Full Wrap") {
                const imageUrls = async () => {
                    let canvasArr = await Promise.all(images.map(async (img, i) => {
                        let { backImgCanvas, faceCanvas } = await PreviewFullWrap(selectedImage, productDetails, primaryCanvas, img.variant.toLowerCase(), i, selectedMode);
                        return {
                            variant: img.variant,
                            color: img.color,
                            url: backImgCanvas.toDataURL(),
                            canvasUrl: faceCanvas.toDataURL(),
                        }
                    }))
                    setImages(canvasArr);
                    toggleSpinner(false);
                }
                imageUrls();
            } else {
                const createImgs = async () => {
                    let canvasArr = await Promise.all(images.map(async (img, i) => {
                        let canvas = await getCanvasWithBackImg(img, productDetails, i, selectedMode);
                        let variantObj = img._id === selectedImage._id ? primaryCanvas.toJSON() : variantCanvas[img.variant]
                        let canvasUrl = await addVariantToCanvas(canvas, variantObj, primaryCanvas, selectedImage.circleImprint);
                        if (selectedMode === "Two Side") {
                            variantObj = secondCanvas.toJSON();
                            canvasUrl = await addVariantToCanvas(canvas, variantObj, secondCanvas);
                        }
                        return {
                            variant: img.variant,
                            color: img.color,
                            url: canvas.toDataURL(),
                            canvasUrl,
                        }
                    }))
                    setImages(canvasArr);
                    toggleSpinner(false);
                }

                createImgs();
            }
        }
    }, [previewModal]);

    useEffect(() => {
        if (previewModal) {
            const handleOutClick = (e) => {
                if (previewImgRef.current && !previewImgRef.current.contains(e.target)) {
                    setFullImage({ show: false, url: "" });
                }
            }

            document.addEventListener('mousedown', handleOutClick);

            return () => document.removeEventListener('mousedown', handleOutClick);
        }
    }, [previewModal]);

    const circleCanvasUrl = (primaryCanvas) => new Promise((resolve, reject) => {
        let canvasEle = document.createElement('canvas');
        let tempCanvs = new fabric.StaticCanvas(canvasEle, {
            height: primaryCanvas.height,
            width: primaryCanvas.width,
        });
        let dataUrl = primaryCanvas.toSVG({suppressPreamble: true});
        const imageScale = selectedImage?.imageScale || 1;
        fabric.loadSVGFromString(dataUrl, function (objects, options) {
            var obj = fabric.util.groupSVGElements(objects, { ...options, crossOrigin: 'anonymous' });
            let circleObj = primaryCanvas.getObjects("circle")[0];
            obj.set({
                clipPath: new fabric.Circle({
                    radius: circleObj.radius,
                    originX: 'center',
                    originY: 'center',
                }),
            });
            tempCanvs.add(obj)
            resolve(tempCanvs.toSVG({
                width: primaryCanvas.width * imageScale,
                height: primaryCanvas.height * imageScale
            }));
        });
    }) 

    const onFinalProceedClick = async () => {
        toggleSpinner(true);
        var data = new FormData();
        data.set('user_id', user.user_id);
        data.set('registerId', user._id);
        data.set('email', user.email);
        data.set('isAnonymous', user.isAnonymous || false);
        data.set('imageId', selectedImage._id);
        data.set('color', selectedImage.color);
        data.set('variant', selectedImage.variant);

        // let espCount = 0;
        // images.forEach(img => {
        //     let blob = dataURItoBlob(img.url);
        //     // let blobCanvas = dataURItoBlob(img.canvasUrl);
        //     data.append('images', blob, `${img.variant}_fullImage.png`);
        //     // if (img.variant.toLowerCase() === "front") {
        //     //     // data.append('images', blobCanvas, `${img.variant}_onlyCanvas.png`);
        //     //     espCount = 1;
        //     //     let uri = primaryCanvas.toDataURL()
        //     //     let espBlob = dataURItoBlob(uri);
        //     //     data.append('images', espBlob, `${img.variant}_espCanvas.png`);
        //     // }
        // })
        // if (!espCount) {

        let fileData = new FormData();
        let uri = null;
        if (selectedMode === "Two Side") {
            let boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultTwoSideBoundaryBox);
            console.log(boundaryBox)
            uri = await twoSideCanvasUrl(boundaryBox, primaryCanvas, secondCanvas);
            // uri = towSideCanvas(); //towSideCanvas.toDataURL({multiplier: selectedImage.imageScale || 1}); //await canvasTiff(towSideCanvas);
            // data.set('canvasHeight', parseInt(towSideCanvas.height));
            // data.set('canvasWidth', parseInt(towSideCanvas.width));
        } else {
            data.set('canvasHeight', primaryCanvas.height);
            data.set('canvasWidth', primaryCanvas.width);
            const imageScale = selectedImage?.imageScale || 1;
            if (selectedImage.circleImprint) {
                uri = await circleCanvasUrl(primaryCanvas);
            } else {
                uri = primaryCanvas.toSVG({
                    width: primaryCanvas.width * imageScale,
                    height: primaryCanvas.height * imageScale
                });
            }
            // console.log(uri)
            //primaryCanvas.toDataURL({multiplier: selectedImage.imageScale || 1}); // await canvasTiff(primaryCanvas) // primaryCanvas.toDataURL()
        }
        let blobCanvas = svgToBlob(uri);
        fileData.append('images', blobCanvas, `full_espCanvas.svg`);
        // }
        if (images.length > 1) {
            let combinedImagesUri = await combineImages(images);
            let blob = dataURItoBlob(combinedImagesUri);
            fileData.append('images', blob, `combined_fullImage.png`);
        } else {
            let combinedImagesUri = images[0].url;
            let blob = dataURItoBlob(combinedImagesUri);
            fileData.append('images', blob, `combined_fullImage.png`);
        }
        dispatch(artworksActions.saveArtwork(data, fileData));
    }

    return (
        <>
            <Modal
                isOpen={previewModal}
                style={customStyles}
                onRequestClose={() => showPreviewModal(false)}
                ariaHideApp={false}
            >
                <div className="preview-modal-body">

                    <div className="share-modal-header">
                        <span>Preview Design</span>
                        <div className="btn-dwnld-btn"
                            onClick={() => showPreviewModal(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="preview-modal-container">
                        <div className="image-list">
                            {
                                images.map((img, i) => (
                                    <div key={i} className="image-preview" onClick={() => setFullImage({ show: true, url: img.url })}>
                                        <img src={img.url} width="200" />
                                        <span>{img.variant}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="footer">
                        <div className="hint">
                            <span>(Click on image to view in fullscreen.)
				<br/>
				<br/>
				<b>NOTE: Please review this preview carefully. This is exactly how your final product will print. Clicking proceed acknowledges that you have approved all images, text and layout of your item as shown here.</b>
			</span>
                        </div>
                        {
                            proceedPreview ? (
                                <div className="proceed-wrap">
                                    <div className="final-msg"></div>
                                    <div className='btn-dwnld-btn' onClick={onFinalProceedClick}>Proceed</div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </Modal>
            {
                fullImage.show ? (
                    <div className="image-fullscreen">
                        {/* <div className="btn-dwnld-btn"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div> */}
                        <img ref={previewImgRef} src={fullImage.url} />
                    </div>
                ) : null
            }
        </>
    )
}
