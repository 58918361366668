import React from 'react';
import TextSubMenu from './submenu/text-sub-menu';
import ImageUploadSubMenu from './submenu/image-upload-sub-menu';
import ClipartsSubMenu from './submenu/cliparts';
import ElementsSubMenu from './submenu/elements-sub-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const AsideSubMenu = ({ activeAsideMenu, toggleSubMenu, showSubMenu }) => {
    return (
        <div className={`aside-submenu ${showSubMenu ? "show" : "not-show"}`}>
            <span
                onClick={() => toggleSubMenu(false)}
                style={{ textAlign: 'right', fontSize: "24px", margin: "10px 10px 0px 10px", cursor: "pointer", display: 'block' }}
                title="Hide Sub-Menu"
            >
                <FontAwesomeIcon icon={faChevronLeft} title="Hide Sub-Menu" />
                <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#63687f" }} title="Hide Sub-Menu" />
                {/* <FontAwesomeIcon icon={faChevronLeft} style={{color: "#949ab7"}} /> */}
            </span>
            {
                activeAsideMenu === "text" ? (
                    <TextSubMenu />
                ) : null
            }
            {
                activeAsideMenu === "photos" ? (
                    <ImageUploadSubMenu />
                ) : null
            }
            {
                activeAsideMenu === "cliparts" ? (
                    <ClipartsSubMenu />
                ) : null
            }
            {
                activeAsideMenu === "elements" ? (
                    <ElementsSubMenu />
                ) : null
            }
        </div>
    )
}

export default AsideSubMenu;