import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { nanoid } from 'nanoid'
import { AuthMap, AuthActions } from './auth.actions';
import { validateTokenApi } from './auth.api';

function* watchValidateToken({payload}) {
    try {
        let isAnonymous = payload.isAnonymous;
        if(!isAnonymous) {
            const data = yield call(validateTokenApi, payload);
            yield put(AuthActions.validateTokenSuccess({...data, isAnonymous: false}));
        }
        if(isAnonymous) {
            let randomId = `anonymous_${Date.now()}_${nanoid()}`
            yield put(AuthActions.validateTokenSuccess({
                user_id: randomId,
                registerId: randomId,
                email: randomId,
                isAnonymous: true
            }));
        }
    } catch (error) {
        console.log(error);
        yield put(AuthActions.validateTokenError(error.message));
    }
}

function* validateTokenSaga() {
    yield takeLatest(AuthMap.VALIDATE_TOKEN, watchValidateToken);
}

export function* authSaga() {
    yield all([
        fork(validateTokenSaga),
    ]);
}